<template>
  <div class="w-full">
    <div
      class="flex w-full items-center cursor-pointer"
      v-on:click="toggleOpen"
    >
      <span class="text-gray-500 w-10 md:w-16">{{
        matchEventDetails.date
      }}</span>
      <div class="flex-1 flex items-center justify-center mx-6">
        <div class="flex-1 flex items-center justify-end gap-2">
          <img
            class="w-5 h-auto"
            :src="matchEventDetails.home_team.logo"
            v-if="matchEventDetails.home_team.logo"
          />
          <h5 class="uppercase font-bold text-xs flex-1">
            {{ matchEventDetails.home_team.short_name }}
          </h5>
        </div>
        <span class="flex flex-1 items-center justify-center">
          <span class="bg-gray-300 p-2 shadow mx-4 whitespace-nowrap">{{
            matchEventDetails.result
          }}</span>
        </span>
        <div class="flex-1 flex items-center justify-end gap-2">
          <h5 class="uppercase font-bold text-xs">
            {{ matchEventDetails.away_team.short_name }}
          </h5>
          <img
            class="w-5 h-auto"
            :src="matchEventDetails.away_team.logo"
            v-if="matchEventDetails.away_team.logo"
          />
        </div>
      </div>
      <div class="flex items-center justify-end gap-2 w-10 md:w-16">
        <img :src="matchEventDetails.competition_logo" class="w-8 h-8" />
        <svg
          style="min-width: 10px;"
          class="transition-all ease-in-out duration-200 "
          :class="{ 'rotate-180': isOpen }"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5.66254L5 0.662537L0 5.66254L10 5.66254Z"
            fill="#0000EE"
          />
        </svg>
      </div>
    </div>

    <div class="p-4 flex flex-col gap-4 md:w-[70%] mx-auto" v-if="isOpen">
      <div
        class="flex items-center justify-between border-b border-gray-300 pb-2"
      >
        <h5 class="">
          {{
            matchEventDetails.home_team.name
              ? matchEventDetails.home_team.name
              : matchEventDetails.home_team
          }}
        </h5>
        <h5 class=" text-right">
          {{
            matchEventDetails.away_team.name
              ? matchEventDetails.away_team.name
              : matchEventDetails.away_team
          }}
        </h5>
      </div>
      <span v-if="matchEventDetails.home_prediction">
        <div
          class="flex items-center justify-between border-gray-300 pb-2"
        >
          <h5 class=""></h5>
          <h5 class="">
            Pre Match Predictions:
          </h5>
          <h5 class=""></h5>
        </div>
        <div
          class="flex items-center justify-between border-b border-gray-300 pb-2"
        >
          <p class="text-gray-500" :class="{ 'font-extrabold' : matchEventDetails.prediction == 'home'}"> {{ Math.round(matchEventDetails.home_prediction) }}%</p>
          <p class="text-gray-500" :class="{ 'font-extrabold' : matchEventDetails.prediction == 'draw'}">{{ Math.round(matchEventDetails.draw_prediction) }}%</p>
          <p class="text-gray-500" :class="{ 'font-extrabold' : matchEventDetails.prediction == 'away'}">{{ Math.round(matchEventDetails.away_prediction) }}%</p>
        </div>
     </span>

      <div
        v-for="(matchEvent, key) in otherEvents"
        class="flex"
        :class="{
          'text-right justify-end':
            matchEventDetails.away_team.id == matchEvent.team_id,
          'flex-row-reverse':
            matchEventDetails.away_team.id != matchEvent.team_id,
        }"
        :key="key"
      >
        <div class="flex-1"></div>
        <div class="text-center flex justify-center">
          <div class="w-4 h-4 flex items-center justify-center">
            <template v-if="key == 0">
              <img
                v-if="
                  matchEvent.type == 'goal' ||
                    matchEvent.type == 'pen_shootout_goal' ||
                    matchEvent.type == 'penalty'
                "
                class="w-3 block h-auto"
                src="@/assets/icons/goal.png"
              />
              <img
                v-if="matchEvent.type == 'own-goal'"
                class="w-3 block h-auto"
                src="@/assets/icons/owngoal.png"
              />
              <img
                v-if="matchEvent.type == 'yellowcard'"
                class="w-3 block h-auto"
                src="@/assets/icons/yellowcard.png"
              />
              <img
                v-if="matchEvent.type == 'redcard'"
                class="w-3 block h-auto"
                src="@/assets/icons/redcard.png"
              />
              <img
                v-if="matchEvent.type == 'yellowred'"
                class="w-3 block h-auto"
                src="@/assets/icons/yellowred.png"
              />
              <img
                v-if="matchEvent.type == 'missed-penalty'"
                class="w-3 block h-auto"
                src="@/assets/icons/missedpenalty.png"
              />
            </template>

            <!-- <img
              v-if="matchEvent.type == 'substitution'"
              class="w-3 block h-auto"
              src="@/assets/icons/substitution.png"
            /> -->
          </div>
        </div>
        <div class="flex-1">
          <p class="text-gray-500">
            {{ matchEvent.player }}
            {{ matchEvent.minute }}'
            <template v-if="matchEvent.extra_minute != 0">
              +{{ matchEvent.extra_minute }}</template
            >
          </p>

          <p class="text-gray-500" v-if="matchEvent.player_off">
            <i>({{ matchEvent.player_off }})</i>
          </p>

          <p class="text-gray-500" v-if="matchEvent.assist_player">
            <i>({{ matchEvent.assist_player }})</i>
          </p>

          <p class="text-gray-500" v-if="matchEvent.type == 'own-goal'">
            <i>(Own Goal)</i>
          </p>

          <p class="text-gray-500" v-if="matchEvent.type == 'penalty'">
            <i>(Penalty)</i>
          </p>

          <p class="text-gray-500" v-if="matchEvent.type == 'missed-penalty'">
            <i>(Missed penalty)</i>
          </p>

          <p
            class="text-gray-500"
            v-if="
              matchEvent.type == 'pen_shootout_goal' ||
                matchEvent.type == 'pen_shootout_miss'
            "
          >
            <i>(Penalty Shootout)</i>
          </p>
        </div>
      </div>
      <div v-if="'xg_for' in matchEventDetails.home_team && 'xg_for' in matchEventDetails.away_team && (matchEventDetails.home_team.xg_for > 0 || matchEventDetails.away_team.xg_for > 0)" class="pt-4 border-t border-gray-300 flex flex-col gap-4">
        <div class="flex">
          <div class="flex-1 mr-3">
            <p class="text-gray-500 font-bold">
              {{ matchEventDetails.home_team.xg_for }}
            </p>
          </div>
          <div class="text-center flex justify-center px-2">
            <div class="w-15 h-4 flex items-center justify-center">
              <h5 class="">
                xG Created
              </h5>
            </div>
          </div>
          <div class="flex-1 ml-3">
              <p class="text-gray-500 text-right font-bold">
                {{ matchEventDetails.away_team.xg_for }}
              </p>
          </div>
        </div>
      </div>  
      <div v-if="'stats' in matchEventDetails && (matchEventDetails.stats.home_team.shots.length > 0 || matchEventDetails.stats.away_team.shots.length > 0)" class="pt-4 border-t border-gray-300 flex flex-col gap-4">
        <div class="flex">
          <div class="flex-1 mr-3">
            <span v-for="player_group in matchEventDetails.stats.home_team.shots">
              <p class="text-gray-500">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
            </p>
            </span>
          </div>
          <div class="text-center flex justify-center px-2">
            <div class="w-4 h-4 flex items-center justify-center">
              <h5 class="">
                Shots
              </h5>
            </div>
          </div>
          <div class="flex-1 ml-3">
            <span v-for="player_group in matchEventDetails.stats.away_team.shots">
              <p class="text-gray-500  text-right">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
              </p>
            </span>
          </div>
        </div>
      </div>  
      <div v-if="'stats' in matchEventDetails && (matchEventDetails.stats.home_team.shots_on_target.length > 0 || matchEventDetails.stats.away_team.shots_on_target.length > 0)" class="pt-4 border-t border-gray-300 flex flex-col gap-4">
        <div class="flex">
          <div class="flex-1 mr-3">
            <span v-for="player_group in matchEventDetails.stats.home_team.shots_on_target">
              <p class="text-gray-500">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
            </p>
            </span>
          </div>
          <div class="text-center flex justify-center px-2">
            <div class="w-4 h-4 flex items-center justify-center">
              <h5 class="">
                On Target
              </h5>
            </div>
          </div>
          <div class="flex-1 ml-3">
            <span v-for="player_group in matchEventDetails.stats.away_team.shots_on_target">
              <p class="text-gray-500  text-right">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
              </p>
            </span>
          </div>
        </div>
      </div>   
      <div v-if="'stats' in matchEventDetails && (matchEventDetails.stats.home_team.offsides.length > 0 || matchEventDetails.stats.away_team.offsides.length > 0)" class="pt-4 border-t border-gray-300 flex flex-col gap-4">
        <div class="flex">
          <div class="flex-1 mr-3">
            <span v-for="player_group in matchEventDetails.stats.home_team.offsides">
              <p class="text-gray-500">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
            </p>
            </span>
          </div>
          <div class="text-center flex justify-center px-2">
            <div class="w-4 h-4 flex items-center justify-center">
              <h5 class="">
                Offsides
              </h5>
            </div>
          </div>
          <div class="flex-1 ml-3">
            <span v-for="player_group in matchEventDetails.stats.away_team.offsides">
              <p class="text-gray-500  text-right">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
              </p>
            </span>
          </div>
        </div>
      </div>                     
      <div v-if="'stats' in matchEventDetails && (matchEventDetails.stats.home_team.fouls.length > 0 || matchEventDetails.stats.away_team.fouls.length > 0)" class="pt-4 border-t border-gray-300 flex flex-col gap-4">
        <div class="flex">
          <div class="flex-1 mr-3">
            <span v-for="player_group in matchEventDetails.stats.home_team.fouls">
              <p class="text-gray-500">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
            </p>
            </span>
          </div>
          <div class="text-center flex justify-center px-2">
            <div class="w-4 h-4 flex items-center justify-center">
              <h5 class="">
                Fouls
              </h5>
            </div>
          </div>
          <div class="flex-1 ml-3">
            <span v-for="player_group in matchEventDetails.stats.away_team.fouls">
              <p class="text-gray-500  text-right">
                <span class="font-bold">[{{ player_group.value }}]</span>
                {{ player_group.players }}
              </p>
            </span>
          </div>
        </div>
      </div>
        <div class="pt-4 border-t border-gray-300 flex flex-col gap-4">
          <div class="flex" v-if="showYellowCards">
            <div class="flex-1">
              <p class="text-gray-500">
                {{
                  yellowCardsByTeam[matchEventDetails.home_team.id] &&
                  yellowCardsByTeam[matchEventDetails.home_team.id].length
                    ? yellowCardsByTeam[matchEventDetails.home_team.id].join(", ")
                    : "-"
                }}
              </p>
            </div>
            <div class="text-center flex justify-center px-2">
              <div class="w-4 h-4 flex items-center justify-center">
                <img
                  class="w-3 block h-auto"
                  src="@/assets/icons/yellowcard.png"
                />
              </div>
            </div>
            <div class="flex-1">
              <p class="text-gray-500 text-right">
                {{
                  yellowCardsByTeam[matchEventDetails.away_team.id] &&
                  yellowCardsByTeam[matchEventDetails.away_team.id].length
                    ? yellowCardsByTeam[matchEventDetails.away_team.id].join(", ")
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="flex" v-if="showRedCards">
            <div class="flex-1">
              <p class="text-gray-500">
                {{
                  redCardsByTeam[matchEventDetails.home_team.id] &&
                  redCardsByTeam[matchEventDetails.home_team.id].length
                    ? redCardsByTeam[matchEventDetails.home_team.id].join(", ")
                    : "-"
                }}
              </p>
            </div>
            <div class="text-center flex justify-center px-2">
              <div class="w-4 h-4 flex items-center justify-center">
                <img class="w-3 block h-auto" src="@/assets/icons/redcard.png" />
              </div>
            </div>
            <div class="flex-1">
              <p class="text-gray-500 text-right">
                {{
                  redCardsByTeam[matchEventDetails.away_team.id] &&
                  redCardsByTeam[matchEventDetails.away_team.id].length
                    ? redCardsByTeam[matchEventDetails.away_team.id].join(", ")
                    : "-"
                }}
              </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MatchEventListCard",
  props: ["matchEventDetails"],
  components: {},

  data() {
    return {
      isOpen: false,
      yellowRedCards: [],
      otherEvents: [],
      yellowCardsByTeam: [],
      redCardsByTeam: [],
    };
  },
  mounted() {
    this.splitEvents();
    this.groupCards();
  },
  methods: {
    splitEvents() {
      this.matchEventDetails.events.forEach((event) => {
        const eventType = event.type.toLowerCase();

        if (
          eventType === "yellowcard" ||
          eventType === "redcard" ||
          eventType === "yellowred"
        ) {
          this.yellowRedCards.push(event);
        } else {
          if (eventType != "substitution") {
            this.otherEvents.push(event);
          }
        }
      });
    },
    groupCards() {
      // Initialize objects to store grouped events
      const yellowCardsByTeam = {};
      const redCardsByTeam = {};

      // Group events by team and card type
      this.yellowRedCards.forEach((event) => {
        const teamId = event.team_id;
        const cardType = event.type.toLowerCase();

        // Check if the team is already in the object, if not, create an empty array
        if (!yellowCardsByTeam[teamId]) {
          yellowCardsByTeam[teamId] = [];
        }
        if (!redCardsByTeam[teamId]) {
          redCardsByTeam[teamId] = [];
        }

        // Push the player name to the appropriate array based on card type
        if (cardType === "yellowcard") {
          yellowCardsByTeam[teamId].push(`${event.player} '${event.minute}`);
        } else if (cardType === "redcard") {
          redCardsByTeam[teamId].push(`${event.player} '${event.minute}`);
        }
      });

      this.yellowCardsByTeam = yellowCardsByTeam;
      this.redCardsByTeam = redCardsByTeam;
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    showYellowCards() {
      let hideHomeTeam = false;
      this.yellowCardsByTeam[this.matchEventDetails.home_team.id] &&
      this.yellowCardsByTeam[this.matchEventDetails.home_team.id].length
        ? this.yellowCardsByTeam[this.matchEventDetails.home_team.id].join(", ")
        : (hideHomeTeam = true);

      let hideAwayTeam = false;
      this.yellowCardsByTeam[this.matchEventDetails.away_team.id] &&
      this.yellowCardsByTeam[this.matchEventDetails.away_team.id].length
        ? this.yellowCardsByTeam[this.matchEventDetails.away_team.id].join(", ")
        : (hideAwayTeam = true);

      if (hideHomeTeam && hideAwayTeam) {
        return false;
      } else {
        return true;
      }
    },
    showRedCards() {
      let hideHomeTeam = false;
      this.redCardsByTeam[this.matchEventDetails.home_team.id] &&
      this.redCardsByTeam[this.matchEventDetails.home_team.id].length
        ? this.redCardsByTeam[this.matchEventDetails.home_team.id].join(", ")
        : (hideHomeTeam = true);

      let hideAwayTeam = false;
      this.redCardsByTeam[this.matchEventDetails.away_team.id] &&
      this.redCardsByTeam[this.matchEventDetails.away_team.id].length
        ? this.redCardsByTeam[this.matchEventDetails.away_team.id].join(", ")
        : (hideAwayTeam = true);

      if (hideHomeTeam && hideAwayTeam) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
};
</script>
